<template>
  <div class="w-full">
    <vs-row
      vs-type="flex"
      class="mt-0 mb-4"
      vs-justify="center"
      vs-align="center"
      vs-w="12"
    >
      <vs-col
        vs-type="flex"
        vs-justify="flex-end"
        vs-align="flex-end"
        vs-w="12"
      >
        <vs-input
          color="dark"
          class="mx-1"
          type="date"
          size="small"
          label="Data De"
          v-model="filtro.dataDe"
        ></vs-input>
        <vs-input
          color="dark"
          class="mx-1"
          type="date"
          label="Data Até"
          size="small"
          v-model="filtro.dataAte"
        ></vs-input>
        <vs-input
          color="dark"
          class="mx-1"
          size="small"
          type="text"
          placeholder
          label="Busca"
          v-model="filtro.busca"
        ></vs-input>
        <div style="display: inline-block; margin-right: 20px">
          <label style="color: black; font-size: 13px">Setor</label><br />
          <el-select
            filterable
            clearable
            size="small"
            placeholder="Selecione..."
            v-model="filtro.setor"
          >
            <el-option
              v-for="setor in setores"
              class="select-info"
              :value="setor.setor"
              :label="setor.setor"
              :key="setor.setor"
            >
            </el-option>
          </el-select>
        </div>
        <el-button
          size="small"
          type="primary"
          class="mx-1"
          plain
          @click="getLogs()"
          >FILTRAR</el-button
        >
      </vs-col>
    </vs-row>
    <div id="doc_table" class="mb-1 vs-con-loading__container">
      <div v-if="!logs || logs.length == 0">
        <div class="pt-20 con-colors">
          <ul class="pt-20">
            <li class="danger-box">
              <h2 class="p-5" style="color: white !important">Nenhum Log</h2>
            </li>
          </ul>
        </div>
      </div>
      <div v-else class="mt-1">
        <vs-table
          class="table_height"
          stripe
          maxHeight="63vh"
          max-items="10"
          pagination
          :data="logs"
        >
          <template slot="header">
            <div class="w-full mb-5">
              <vs-row
                vs-w="12"
                vs-type="flex"
                vs-align="center"
                vs-justify="center"
              >
                <vs-col
                  vs-w="6"
                  vs-type="flex"
                  vs-align="flex-start"
                  vs-justify="flex-start"
                >
                  <h4 style="color: #b4aa99">Logs do sistema</h4>
                </vs-col>
                <vs-col
                  vs-w="6"
                  vs-type="flex"
                  vs-align="flex-end"
                  vs-justify="flex-end"
                >
                  <vs-button
                    color="success"
                    type="relief"
                    size="small"
                    @click="exportToExcel()"
                    >EXCEL</vs-button
                  >
                </vs-col>
              </vs-row>
            </div>
          </template>
          <template slot="thead">
            <vs-th sort-key="texto">Ação</vs-th>
            <vs-th sort-key="funcao">Função</vs-th>
            <vs-th sort-key="colaborador">Usuário</vs-th>
            <vs-th class="center" sort-key="setor">Setor</vs-th>
            <vs-th class="center" sort-key="ip">IP</vs-th>
            <vs-th class="center" sort-key="data_criacao">Data Criação</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :key="index" v-for="(tr, index) in data">
              <vs-td :data="tr.texto">{{ tr.texto }}</vs-td>
              <vs-td :data="tr.funcao">{{ tr.funcao }}</vs-td>
              <vs-td :data="tr.id_colaborador">{{ tr.colaborador }}</vs-td>
              <vs-td class="text-center" :data="tr.setor">{{ tr.setor }}</vs-td>
              <vs-td class="text-center" :data="tr.ip">{{ tr.ip }}</vs-td>
              <vs-td class="text-center" :data="tr.data_criacao">{{
                tr.data_criacao
              }}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </div>
  </div>
</template>

<script>
import components from "@/components/default/exports.js"
export default {
  data () {
    return {
      logs: [],
      setores: [],
      filtro: {
        dataHoje: this.$formartData.dataFiltro()
      }
    }
  },
  methods: {
    async getLogs () {
      await this.$vs.loading()
      try {
        this.logs = await this.$http.post(`getLogs`, this.filtro)
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        await this.$vs.loading.close()
      }
    },
    async getSetoresLogs () {
      try {
        this.setores = await this.$http.get(`getSetoresLogs`)
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async exportToExcel () {
      try {
        import("@/vendor/Export2Excel").then(async excel => {
          let headerTitle = [
            "Ação",
            "Função",
            "Usuário",
            "Setor",
            "IP",
            "Data Criação"
          ]
          let headerVal = [
            "texto",
            "funcao",
            "colaborador",
            "setor",
            "ip",
            "data_criacao",
          ]
          const list = this.logs
          const data = await this.formatJson(headerVal, list)
          const dataFiltro = `${this.filtro.dataDe && this.filtro.dataAte ? this.filtro.dataDe + ' até ' + this.filtro.dataAte : this.filtro.dataHoje}`
          excel.export_json_to_excel({
            header: headerTitle,
            data,
            filename: `Logs do Sistema (${dataFiltro})`,
            autoWidth: true,
            bookType: "xlsx"
          })
        })
        this.$vs.loading.close()
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map(v =>
        filterVal.map(j => {
          return v[j]
        })
      )
    }
  },
  async mounted () {
    await this.getLogs()
    await this.getSetoresLogs()
  },
  components: {
    ...components
  }
};
</script>

<style lang="scss">
.col {
  width: auto;
}
.containerItens {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
}
thead tr th.center {
  .vs-table-text {
    justify-content: center !important;
  }
}
</style>
